export const DiaryDiamondColors = [
  { id: 1, img: "yellow", name: "Yellow", value: "Yellow" },
  {
    id: 2,
    img: "greenish-yellow",
    name: "Greenish Yellow",
    value: "Greenish Yellow",
  },
  {
    id: 2,
    img: "greenish-yellow",
    name: "Greenish Yellow",
    value: "Green Yellow",
  },
  {
    id: 3,
    img: "orange-yellow",
    name: "Orange Yellow",
    value: "Orange Yellow",
  },
  {
    id: 4,
    img: "pink",
    name: "Pink",
    value: "Pink",
  },
  { id: 5, img: "orangy-pink", name: "Orangy Pink", value: "Orangy Pink" },
  {
    id: 6,
    img: "purplish-pink",
    name: "Purplish Pink",
    value: "Purplish Pink",
  },
  {
    id: 7,
    img: "pinkish-purple",
    name: "Pink/ish Purple",
    value: "Pink/ish Purple",
  },
  { id: 8, img: "purple", name: "Purple", value: "Purple" },
  { id: 8, img: "pink", name: "Purple Pink", value: "Purple Pink" },
  {
    id: 9,
    img: "brownish-pink",
    name: "Brownish Pink",
    value: "Brownish Pink",
  },
  { id: 10, img: "brown-pink", name: "Brown Pink", value: "Brown Pink" },
  { id: 11, img: "blue", name: "Blue", value: "Blue" },
  { id: 12, img: "grey-blue", name: "Gray Blue", value: "Gray Blue" },
  { id: 13, img: "green-blue", name: "Green Blue", value: "Green Blue" },
  {
    id: 14,
    img: "greenish-blue",
    name: "Greenish Blue",
    value: "Greenish Blue",
  },
  { id: 15, img: "grayish-blue", name: "Grayish Blue", value: "Grayish Blue" },
  { id: 16, img: "orange", name: "Orange", value: "Orange" },
  {
    id: 17,
    img: "yellow-orange",
    name: "Yellow Orange",
    value: "Yellow Orange",
  },
  {
    id: 18,
    img: "yellowish-orange",
    name: "Yellowish Orange",
    value: "Yellowish Orange",
  },
  { id: 19, img: "brown-orange", name: "Brown Orange", value: "Brown Orange" },
  { id: 20, img: "green", name: "Green", value: "Green" },
  {
    id: 21,
    img: "yellow-green",
    name: "Yellow Green",
    value: "Yellow Green",
  },
  {
    id: 22,
    img: "bluegreen",
    name: "Blue Green",
    value: "Blue Green",
  },
  {
    id: 23,
    img: "bluish-green",
    name: "Bluish Green",
    value: "Bluish Green",
  },
  { id: 24, img: "red", name: "Red", value: "Red" },
  { id: 25, img: "purplish-red", name: "Purplish Red", value: "Purplish Red" },
  { id: 26, img: "grey", name: "Gray", value: "Gray" },
  { id: 27, img: "blue-grey", name: "Blue Gray", value: "Blue Gray" },

  { id: 27, img: "purple-brown-grey", value: "Purple with Brown or Gray" },
  { id: 28, img: "grey-violet", value: "Gray-violet - violet gray" },
  { id: 29, img: "violet-blue", value: "Violet-blue to blue violet" },
  { id: 30, img: "violet", value: "Violet" },
  { id: 31, img: "greyish-yellow-green", value: "Grayish yellow-green" },
  {
    id: 32,
    img: "brownish-greenish-yellow",
    value: "Brownish greenish yellow",
  },
  { id: 33, img: "greyish-green-yellow", value: "Grayish green-yellow" },
  { id: 34, img: "green-grey", value: "Green gray to gray green" },
  {
    id: 35,
    img: "greenish-yellow",
    name: "Green/ish Yellow",
    value: "Green/ish Yellow",
  },
  {
    id: 36,
    img: "purplish-pink",
    name: "Purple/ish Pink",
    value: "Purple/ish Pink",
  },
  {
    id: 37,
    img: "purplish-pink",
    name: "Pink - Purplish Pink",
    value: "Pink - Purplish Pink",
  },
  {
    id: 38,
    img: "yellow-green",
    name: "Yellow/ish Green",
    value: "Yellow/ish Green",
  },
  { id: 39, img: "blue-grey", name: "Blue/ish Gray", value: "Blue/ish Gray" },
  {
    id: 40,
    img: "pink",
    name: "Pink/Purple/ish Pink",
    value: "Pink/Purple/ish Pink",
  },
  { id: 41, img: "orangy-yellow", name: "Orangy Yellow", value: "Orangy Yellow" },
  {
    id: 42,
    img: "pink",
    name: "Pink/ish Purple",
    value: "Pink/ish Purple",
  },
  {
    id: 43,
    img: "brownish-orange",
    name: "Brownish Orange",
    value: "Brownish Orange"
  },
];

export const DiaryDiamondShapes = [
  { id: 1, img: "radient", value: "Radiant" },
  { id: 2, img: "cushion", value: "Cushion" },
  { id: 3, img: "oval", value: "Oval" },
  { id: 4, img: "heart", value: "Heart" },
  { id: 5, img: "pear", value: "Pear" },
  { id: 6, img: "emerald", value: "Emerald" },
  { id: 7, img: "round", value: "Round" },
  { id: 8, img: "marquise", value: "Marquise" },
  { id: 9, img: "hexagon", value: "Hexagon" },
  { id: 10, img: "triangular-brilliant", value: "Triangular Brilliant" },
  { id: 11, img: "octagon", value: "Octagon" },
  { id: 12, img: "princess", value: "Princess" },
  { id: 13, img: "trilliant", value: "Trilliant" },
  { id: 14, img: "kite", value: "Kite" },
  { id: 15, img: "shield", value: "Shield" },
];

export const DiaryOriginVideo = [
  {
    origin: "Angola - Catoca",
    video: "Catoca.mp4",
  },
  
  {
    origin: "Angola - Lulo (Lucapa)",
    video: "Lulo.mp4",
  },
  {
    origin: "Australia - Argyle",
    video: "Argyle mine.mp4",
  },
  {
    origin: "Botswana - Jwaneng (De Beers)",
    video: "Jwaneng.mp4",
  },
  {
    origin: "Botswana - Orapa",
    video: "Botswana - Orapa.mp4",
  },
  {
    origin: "Brazil - Diamantina",
    video: "Diamantina Brazil mine.mp4",
  },
  {
    origin: "Canada - Diavik (Rio Tinto)",
    video: "Diavik.mp4",
  },
  {
    origin: "Canada - Ekati",
    video: "Misery mine.mp4",
  },
  {
    origin: "Congo - Kinshasa",
    video: "Mbuji-Mayi mine.mp4",
  },
  {
    origin: "Lesotho - Kao",
    video: "Kao - Lesoto.mp4",
  },
  {
    origin: "Russia - Almazy Anabara",
    video: "Sever Almaz.mp4",
  },
    
  {
    origin: "Sierra Leone - Zimmi",
    video: "Zimmi.mp4",
  },
  {
    origin: "South Africa - Rooipoort",
    video: "South Africa - Rooipoort.mp4",
  },
  {
    origin: "South Africa - Alexkor",
    video: "Alexkor mine.mp4",
  },
  {
    origin: "South Africa - De Punt Mine (Trans Hex)",
    video: "De Punt.mp4",
  },
  {
    origin: "South Africa - Finsch",
    video: "Finch.mp4",
  },
  {
    origin: "South Africa - Premier (Cullinan, Petra)",
    video: "Culinan.mp4",
  },
  {
    origin: "South Africa - The Somiluana Mine (Trans Hex)",
    video: "Somiluana - trans Hex.mp4",
  },
  {
    origin: "South Africa - Venetia",
    video: "Venetia mine South Africa.mp4",
  },
  {
    origin: "South Africa - Williamson (Tanzania)",
    video: "Williamson.mp4",
  },
  {
    origin: "Zimbabwe - Murowa",
    video: "Murowa.mp4",
  },
];

export const DiaryCauseShape1 = [
  {
    code: "CB",
    shape: "Cushion",
  },
  {
    code: "CCSB",
    shape: "Radiant",
  },
  {
    code: "CIRC",
    shape: "Round",
  },
  {
    code: "CMB",
    shape: "Cushion",
  },
  {
    code: "CRB",
    shape: "Radiant",
  },
  {
    code: "CRMB",
    shape: "Radiant",
  },
  {
    code: "CRSC",
    shape: "Emerald",
  },
  {
    code: "CRX",
    shape: "Radiant",
  },
  {
    code: "CSMB",
    shape: "Radiant",
  },
  {
    code: "CSSC",
    shape: "Emerald",
  },
  {
    code: "CSX",
    shape: "Radiant",
  },
  {
    code: "CX",
    shape: "Cushion",
  },
  {
    code: "EM",
    shape: "Emerald",
  },
  {
    code: "HB",
    shape: "Heart",
  },
  {
    code: "HMB",
    shape: "Heart",
  },
  
  {
    code: "MHB",
    shape: "Heart",
  },
  {
    code: "MHX",
    shape: "Heart",
  },
  {
    code: "MMB",
    shape: "Marquise",
  },
  {
    code: "MMC",
    shape: "Marquise",
  },
  {
    code: "MMX",
    shape: "Marquise",
  },
  {
    code: "MOB",
    shape: "Oval",
  },
  {
    code: "MOX",
    shape: "Oval",
  },
  {
    code: "MPX",
    shape: "Pear",
  },
  {
    code: "RBC",
    shape: "Round",
  },
  {
    code: 'PX',
    shape: 'Pear',
  },
  {
    code: "OX",
    shape: "Oval",
  },
];

export const FluorescenceShadow = [
  {
    fluorescence: "Faint Blue",
    color: "Blue",
  },
  {
    fluorescence: "Medium Blue",
    color: "Blue",
  },
  {
    fluorescence: "Strong Blue",
    color: "Blue",
  },
  {
    fluorescence: "Very Strong Blue",
    color: "Blue",
  },
  {
    fluorescence: "Yellow",
    color: "Yellow",
  },
  {
    fluorescence: "Orange",
    color: "Orange",
  },
  {
    fluorescence: "Green",
    color: "Green",
  },
];

export const DiaryCauseShape = [
  {
    code: "CRMB",
    shape: "Radiant",
  },
  {
    code: "A",
    shape: "",
  },
  {
    code: "AB",
    shape: "",
  },
  {
    code: "B",
    shape: "",
  },
  {
    code: "BAT",
    shape: "",
  },
  {
    code: "BCRSC",
    shape: "",
  },
  {
    code: "BIRD",
    shape: "",
  },
  {
    code: "BOAT",
    shape: "",
  },
  {
    code: "BOWL",
    shape: "",
  },
  {
    code: "BR",
    shape: "",
  },
  {
    code: "BRSC",
    shape: "",
  },
  {
    code: "BRX",
    shape: "",
  },
  {
    code: "BSSC",
    shape: "",
  },
  {
    code: "BTOB",
    shape: "",
  },
  {
    code: "BULL",
    shape: "",
  },
  {
    code: "BUT",
    shape: "",
  },
  {
    code: "BAT",
    shape: "",
  },
  {
    code: "CB",
    shape: "Cushion",
  },
  {
    code: "CCRRC",
    shape: "",
  },
  {
    code: "CCSB",
    shape: "Radiant",
  },
  {
    code: "CDRC",
    shape: "",
  },
  {
    code: "CHMNM",
    shape: "",
  },
  {
    code: "CIRC",
    shape: "Round",
  },
  {
    code: "CLUB",
    shape: "",
  },
  {
    code: "CLVR",
    shape: "",
  },
  {
    code: "CMB",
    shape: "Cushion",
  },
  {
    code: "CNC",
    shape: "",
  },
  {
    code: "CPC",
    shape: "",
  },
  {
    code: "CRB",
    shape: "Radiant",
  },
  {
    code: "CRC",
    shape: "",
  },
  {
    code: "CRHX",
    shape: "",
  },
  {
    code: "CRMB",
    shape: "Radiant",
  },
  {
    code: "CRNC",
    shape: "",
  },
  {
    code: "CROSS",
    shape: "",
  },
  {
    code: "CRPC",
    shape: "",
  },
  {
    code: "CRRC",
    shape: "",
  },
  {
    code: "CRSC",
    shape: "Emerald",
  },
  {
    code: "CRSS",
    shape: "",
  },
  {
    code: "CRSSSC",
    shape: "",
  },
  {
    code: "CRSSX",
    shape: "",
  },
  {
    code: "CRX",
    shape: "Radiant",
  },
  {
    code: "CSC",
    shape: "",
  },
  {
    code: "CSMB",
    shape: "Radiant",
  },
  {
    code: "CSPC",
    shape: "",
  },
  {
    code: "CSSC",
    shape: "Emerald",
  },
  {
    code: "CSX",
    shape: "Radiant",
  },
  {
    code: "CTMB",
    shape: "",
  },
  {
    code: "CTPC",
    shape: "",
  },
  {
    code: "CTPMB",
    shape: "",
  },
  {
    code: "CTPP",
    shape: "",
  },
  {
    code: "CTPSC",
    shape: "",
  },
  {
    code: "CTPX",
    shape: "",
  },
  {
    code: "CTRMB",
    shape: "",
  },
  {
    code: "CTRPC",
    shape: "",
  },
  {
    code: "CTRSC",
    shape: "",
  },
  {
    code: "CTRX",
    shape: "",
  },
  {
    code: "CX",
    shape: "Cushion",
  },
  {
    code: "DB",
    shape: "",
  },
  {
    code: "DHRSE",
    shape: "",
  },
  {
    code: "DHSC",
    shape: "",
  },
  {
    code: "DLPH",
    shape: "",
  },
  {
    code: "DLPNDR",
    shape: "",
  },
  {
    code: "DMB",
    shape: "",
  },
  {
    code: "DOG",
    shape: "",
  },
  {
    code: "DOLL",
    shape: "",
  },
  {
    code: "DUCK",
    shape: "",
  },
  {
    code: "EAGLE",
    shape: "",
  },
  {
    code: "EIGHT",
    shape: "",
  },
  {
    code: "ELPHT",
    shape: "",
  },
  {
    code: "EM",
    shape: "Emerald",
  },
  {
    code: "FACE",
    shape: "",
  },
  {
    code: "FBD",
    shape: "",
  },
  {
    code: "FISH",
    shape: "",
  },
  {
    code: "FLM",
    shape: "",
  },
  {
    code: "FLMMB",
    shape: "",
  },
  {
    code: "FLWR",
    shape: "",
  },
  {
    code: "FO",
    shape: "",
  },
  {
    code: "GUN",
    shape: "",
  },
  {
    code: "H",
    shape: "",
  },
  {
    code: "HB",
    shape: "Heart",
  },
  {
    code: "HDRC",
    shape: "",
  },
  {
    code: "HMB",
    shape: "Heart",
  },
  {
    code: "HMN",
    shape: "",
  },
  {
    code: "HNC",
    shape: "",
  },
  {
    code: "HPC",
    shape: "",
  },
  {
    code: "HRC",
    shape: "",
  },
  {
    code: "HRSE",
    shape: "",
  },
  {
    code: "HSC",
    shape: "",
  },
  {
    code: "HX",
    shape: "Heart",
  },
  {
    code: "HXB",
    shape: "",
  },
  {
    code: "HXC",
    shape: "",
  },
  {
    code: "HXDRC",
    shape: "",
  },
  {
    code: "HXMB",
    shape: "",
  },
  {
    code: "HXPC",
    shape: "",
  },
  {
    code: "HXRC",
    shape: "",
  },
  {
    code: "HXSC",
    shape: "",
  },
  {
    code: "HXX",
    shape: "",
  },
  {
    code: "INXC",
    shape: "",
  },
  {
    code: "ISC",
    shape: "",
  },
  {
    code: "KB",
    shape: "",
  },
  {
    code: "KDRC",
    shape: "",
  },
  {
    code: "KSC",
    shape: "",
  },
  {
    code: "KX",
    shape: "",
  },
  {
    code: "LB",
    shape: "",
  },
  {
    code: "LEAF",
    shape: "",
  },
  {
    code: "LIPS",
    shape: "",
  },
  {
    code: "LMB",
    shape: "",
  },
  {
    code: "LPC",
    shape: "",
  },
  {
    code: "LSC",
    shape: "",
  },
  {
    code: "LX",
    shape: "",
  },
  {
    code: "MB",
    shape: "Marquise",
  },
  {
    code: "MDRC",
    shape: "",
  },
  {
    code: "MHB",
    shape: "Heart",
  },
  {
    code: "MHDRC",
    shape: "",
  },
  {
    code: "MHMN",
    shape: "",
  },
  {
    code: "MHNC",
    shape: "",
  },
  {
    code: "MHPC",
    shape: "",
  },
  {
    code: "MHRC",
    shape: "",
  },
  {
    code: "MHSC",
    shape: "",
  },
  {
    code: "MHX",
    shape: "Heart",
  },
  {
    code: "MHXB",
    shape: "",
  },
  {
    code: "MHXMC",
    shape: "",
  },
  {
    code: "MHXPC",
    shape: "",
  },
  {
    code: "MHXRC",
    shape: "",
  },
  {
    code: "MHXSC",
    shape: "",
  },
  {
    code: "MHXX",
    shape: "",
  },
  {
    code: "MKB",
    shape: "",
  },
  {
    code: "MKMC",
    shape: "",
  },
  {
    code: "MKNC",
    shape: "",
  },
  {
    code: "MKPC",
    shape: "",
  },
  {
    code: "MKSC",
    shape: "",
  },
  {
    code: "MKX",
    shape: "",
  },
  {
    code: "MLB",
    shape: "",
  },
  {
    code: "MLMB",
    shape: "",
  },
  {
    code: "MLNC",
    shape: "",
  },
  {
    code: "MLPC",
    shape: "",
  },
  {
    code: "MLRC",
    shape: "",
  },
  {
    code: "MLSC",
    shape: "",
  },
  {
    code: "MLX",
    shape: "",
  },
  {
    code: "MMB",
    shape: "Marquise",
  },
  {
    code: "MMC",
    shape: "Marquise",
  },
  {
    code: "MMDRC",
    shape: "",
  },
  {
    code: "MMSC",
    shape: "",
  },
  {
    code: "MMX",
    shape: "Marquise",
  },
  {
    code: "MNC",
    shape: "",
  },
  {
    code: "MOB",
    shape: "Oval",
  },
  {
    code: "MOC",
    shape: "",
  },
  {
    code: "MOCB",
    shape: "",
  },
  {
    code: "MOCS",
    shape: "",
  },
  {
    code: "MOCSC",
    shape: "",
  },
  {
    code: "MOCX",
    shape: "",
  },
  {
    code: "MOHSC",
    shape: "",
  },
  {
    code: "MOSC",
    shape: "",
  },
  {
    code: "MOU",
    shape: "",
  },
  {
    code: "MOUSE",
    shape: "",
  },
  {
    code: "MOX",
    shape: "Oval",
  },
  {
    code: "MPB",
    shape: "Pear",
  },
  {
    code: "MPC",
    shape: "",
  },
  {
    code: "MPDRC",
    shape: "",
  },
  {
    code: "MPNC",
    shape: "",
  },
  {
    code: "MPPC",
    shape: "",
  },
  {
    code: "MPRC",
    shape: "",
  },
  {
    code: "MPSC",
    shape: "",
  },
  {
    code: "MPTB",
    shape: "",
  },
  {
    code: "MPTSC",
    shape: "",
  },
  {
    code: "MPTX",
    shape: "",
  },
  {
    code: "MPX",
    shape: "Pear",
  },
  {
    code: "MRB",
    shape: "Cushion",
  },
  {
    code: "MRB",
    shape: "",
  },
  {
    code: "MRC",
    shape: "",
  },
  {
    code: "MRDRC",
    shape: "",
  },
  {
    code: "MRHB",
    shape: "",
  },
  {
    code: "MRMC",
    shape: "",
  },
  {
    code: "MRNC",
    shape: "",
  },
  {
    code: "MRPC",
    shape: "",
  },
  {
    code: "MRSC",
    shape: "",
  },
  {
    code: "MSB",
    shape: "",
  },
  {
    code: "MSC",
    shape: "",
  },
  {
    code: "MSDRC",
    shape: "",
  },
  {
    code: "MSHB",
    shape: "",
  },
  {
    code: "MSHDRC",
    shape: "",
  },
  {
    code: "MSHLD",
    shape: "",
  },
  {
    code: "MSHMX",
    shape: "",
  },
  {
    code: "MSHNC",
    shape: "",
  },
  {
    code: "MSHPC",
    shape: "",
  },
  {
    code: "MSHRC",
    shape: "",
  },
  {
    code: "MSHSC",
    shape: "",
  },
  {
    code: "MSHX",
    shape: "",
  },
  {
    code: "MSNC",
    shape: "",
  },
  {
    code: "MSPC",
    shape: "",
  },
  {
    code: "MSRC",
    shape: "",
  },
  {
    code: "MSSC",
    shape: "",
  },
  {
    code: "MSSN",
    shape: "",
  },
  {
    code: "MSX",
    shape: "",
  },
  {
    code: "MTDRC",
    shape: "",
  },
  {
    code: "MTNC",
    shape: "",
  },
  {
    code: "MTPB",
    shape: "",
  },
  {
    code: "MTPC",
    shape: "",
  },
  {
    code: "MTPP",
    shape: "",
  },
  {
    code: "MTPSC",
    shape: "",
  },
  {
    code: "MTPX",
    shape: "",
  },
  {
    code: "MTRB",
    shape: "",
  },
  {
    code: "MTRDC",
    shape: "",
  },
  {
    code: "MTRRC",
    shape: "",
  },
  {
    code: "MTRSC",
    shape: "",
  },
  {
    code: "MTRX",
    shape: "",
  },
  {
    code: "MTSC",
    shape: "",
  },
  {
    code: "NA",
    shape: "",
  },
  {
    code: "NSMB",
    shape: "",
  },
  {
    code: "OB",
    shape: "Oval",
  },
  {
    code: "OCB",
    shape: "",
  },
  {
    code: "OCMB",
    shape: "",
  },
  {
    code: "OCPC",
    shape: "",
  },
  {
    code: "OCSC",
    shape: "",
  },
  {
    code: "OCX",
    shape: "",
  },
  {
    code: "OCRC",
    shape: "",
  },
  {
    code: "OEB",
    shape: "",
  },
  {
    code: "OMB",
    shape: "Oval",
  },
  {
    code: "OMPC",
    shape: "",
  },
  {
    code: "ONC",
    shape: "",
  },
  {
    code: "OPC",
    shape: "",
  },
  {
    code: "ORC",
    shape: "",
  },
  {
    code: "OSC",
    shape: "",
  },
  {
    code: "OTH",
    shape: "",
  },
  {
    code: "OX",
    shape: "Oval",
  },
  {
    code: "P",
    shape: "",
  },
  {
    code: "PB",
    shape: "Pear",
  },
  {
    code: "PDRC",
    shape: "",
  },
  {
    code: "PFC",
    shape: "",
  },
  {
    code: "PFR",
    shape: "",
  },
  {
    code: "PMB",
    shape: "Pear",
  },
  {
    code: "PNC",
    shape: "",
  },
  {
    code: "PPC",
    shape: "",
  },
  {
    code: "PRC",
    shape: "",
  },
  {
    code: "PSC",
    shape: "",
  },
  {
    code: "PTMB",
    shape: "",
  },
  {
    code: "PTSC",
    shape: "",
  },
  {
    code: "PTX",
    shape: "",
  },
  {
    code: "PX",
    shape: "Pear",
  },
  {
    code: "R",
    shape: "",
  },
  {
    code: "RBC",
    shape: "Round",
  },
  {
    code: "RCKT",
    shape: "",
  },
  {
    code: "RCRB",
    shape: "Cushion",
  },
  {
    code: "RCRHX",
    shape: "",
  },
  {
    code: "RCRMB",
    shape: "Cushion",
  },
  {
    code: "RCRPC",
    shape: "",
  },
  {
    code: "RCRSC",
    shape: "",
  },
  {
    code: "RCRX",
    shape: "",
  },
  {
    code: "RCSB",
    shape: "Cushion",
  },
  {
    code: "RCSMB",
    shape: "Cushion",
  },
  {
    code: "RCSSC",
    shape: "Cushion",
  },
  {
    code: "RCSX",
    shape: "Cushion",
  },
  {
    code: "RCTMB",
    shape: "",
  },
  {
    code: "RCTSC",
    shape: "",
  },
  {
    code: "RCTX",
    shape: "",
  },
  {
    code: "RDRC",
    shape: "",
  },
  {
    code: "REDRC",
    shape: "",
  },
  {
    code: "RHMB",
    shape: "",
  },
  {
    code: "#VALUE!",
    shape: "",
  },
  {
    code: "RHSC",
    shape: "",
  },
  {
    code: "RMB",
    shape: "Round",
  },
  {
    code: "RMBC",
    shape: "",
  },
  {
    code: "RNC",
    shape: "",
  },
  {
    code: "RPC",
    shape: "",
  },
  {
    code: "RRC",
    shape: "",
  },
  {
    code: "RSC",
    shape: "",
  },
  {
    code: "RTRMB",
    shape: "",
  },
  {
    code: "RX",
    shape: "",
  },
  {
    code: "SDRC",
    shape: "",
  },
  {
    code: "SEM",
    shape: "Emerald",
  },
  {
    code: "SERB",
    shape: "",
  },
  {
    code: "SHB",
    shape: "",
  },
  {
    code: "SHSC",
    shape: "",
  },
  {
    code: "SHX",
    shape: "",
  },
  {
    code: "SMB",
    shape: "",
  },
  {
    code: "SNC",
    shape: "",
  },
  {
    code: "SPC",
    shape: "",
  },
  {
    code: "SSB",
    shape: "",
  },
  {
    code: "SSC",
    shape: "",
  },
  {
    code: "STAR",
    shape: "",
  },
  {
    code: "SX",
    shape: "",
  },
  {
    code: "TAPBA",
    shape: "",
  },
  {
    code: "THC",
    shape: "",
  },
  {
    code: "TPMB",
    shape: "",
  },
  {
    code: "TPPC",
    shape: "",
  },
  {
    code: "TPSC",
    shape: "",
  },
  {
    code: "TRB",
    shape: "",
  },
  {
    code: "TREE",
    shape: "",
  },
  {
    code: "TRIX",
    shape: "",
  },
  {
    code: "TRMB",
    shape: "",
  },
  {
    code: "TRPC",
    shape: "",
  },
  {
    code: "TRSC",
    shape: "",
  },
  {
    code: "TS",
    shape: "",
  },
  {
    code: 'PX',
    shape: 'Pear',
  }
];

export const DiaryCauseImage = [
  {
    color: "Green Blue",
    img: "coc_green_blue.png",
  },
  {
    color: "Greenish Blue",
    img: "coc_green_blue.png",
  },
  {
    color: "Blue Green",
    img: "coc_green_blue.png",
  },
  {
    color: "Bluish Green",
    img: "coc_green_blue.png",
  },
  {
    color: "Greenish Yellow",
    img: "coc_greenish_yellow_green.png",
  },
  {
    color: "Yellow Green",
    img: "coc_greenish_yellow_green.png",
  },
  {
    color: "Green/ish Yellow",
    img: "coc_greenish_yellow_green.png",
  },
  {
    color: "Yellow/ish Green",
    img: "coc_greenish_yellow_green.png",
  },
  {
    color: "Red",
    img: "coc_red.png",
  },
  {
    color: "Purple",
    img: "coc_purple.png",
  },
  {
    color: "Pink - Purplish Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Purple/ish Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Pink/Purple/ish Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Orangy Pink",
    img: "coc_orangy_pink.png",
  },
  {
    color: "Brownish Pink",
    img: "coc_brown_ish_pink.png",
  },
  {
    color: "Brown Pink",
    img: "coc_brown_ish_pink.png",
  },
  {
    color: "Purplish Red",
    img: "coc_purplish-red.PNG",
  },
  {
    color: "Purplish Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Pink",
    img: "coc_pink_purplish_ink.png",
  },
  {
    color: "Yellow",
    img: "coc_yellow.png",
  },
  {
    color: "Blue",
    img: "coc_gray_ish_blue.png",
  },
  {
    color: "Gray Blue",
    img: "coc_gray_ish_blue.png",
  },
  {
    color: "Grayish Blue",
    img: "coc_gray_ish_blue.png",
  },
  {
    color: "Gray",
    img: "coc_gray.png",
  },
  {
    color: "Yellow Orange",
    img: "coc_orange.png",
  },
  {
    color: "Yellowish Orange",
    img: "coc_orange.png",
  },
  {
    color: "Brownish Orange",
    img: "coc_yellow_ish_orange.png",
  },
  {
    color: "Brown Orange",
    img: "coc_yellow_ish_orange.png",
  },
  {
    color: "Blue Gray",
    img: "coc_yellow_ish_orange.png",
  },
  {
    color: "Orange",
    img: "coc_orange.png",
  },
  {
    color: "Green",
    img: "coc_green.png",
  },
  {
    color: "Orange Yellow",
    img: "coc_orange_yellow.png",
  },
  {
    color: "Orangy Yellow",
    img: "coc_orange_yellow.png",
  },
  {
    color: "Pinkish Purple",
    img: "coc_pinkish_purple.png",
  },
  {
    color: "Pink Purple",
    img: "coc_pinkish_purple.png",
  },
  {
    color: "Pink/ish Purple",
    img: "coc_pinkish_purple.png",
  },
  {
    color: "Blueish Gray",
    img: "coc_yellow_ish_orange.png",
  },
  {
    color: "Blue/ish Gray",
    img: "coc_yellow_ish_orange.png",
  },
  {
    color: "Yellowish Green",
    img: "coc_greenish_yellow_green.png",
  },
  
];

export const DiaryFluorescenceImage = [
  {
    color: "Faint Blue",
    img: "Faint-Blue",
  },
  {
    color: "Faint Green",
    img: "Faint-Green",
  },
  {
    color: "Faint Orang",
    img: "Faint-Orang",
  },
  {
    color: "Faint Yellow",
    img: "Faint-Yellow",
  },
  {
    color: "Medium Blue",
    img: "Medium-Blue",
  },
  {
    color: "Medium Green",
    img: "Medium-Green",
  },
  {
    color: "Medium Orange",
    img: "Medium-Orange",
  },
  {
    color: "Medium Yellow",
    img: "Medium-Yellow",
  },
  {
    color: "Strong Blue",
    img: "Strong-Blue",
  },
  {
    color: "Strong Green",
    img: "Strong-Green",
  },
  {
    color: "Strong Orange",
    img: "Strong-Orange",
  },
  {
    color: "Strong Yellow",
    img: "Strong-Yellow",
  },
  {
    color: "Very Strong Blue",
    img: "Very-Strong-Blue",
  },
  {
    color: "Very Strong Green",
    img: "Very-Strong-Green",
  },
  {
    color: "Very Strong Orange",
    img: "Very-Strong-Orange",
  },
  {
    color: "Very Strong Yellow",
    img: "Very-Strong-Yellow",
  },
];

export const DiaryLoaderMsg = [
  {
    id: "originBox",
    msg: "Generating satellite images of the mine",
  },
  {
    id: "rarityBox",
    msg: "Calculating rarity",
  },
  {
    id: "colorCauseBox",
    msg: "Presenting the cause of color",
  },
  {
    id: "clarityBox",
    msg: "Fetching information on the clarity",
  },
  {
    id: "surfaceAreaBox",
    msg: "Calculating the face-up appearance of your diamond",
  },
  {
    id: "shapeBox",
    msg: "Explaining the fundamentals of the shape",
  },
  {
    id: "fluorescenceBox",
    msg: "Explaining fluorescent",
  },
  {
    id: "luxuryHistoryBox",
    msg: "Preparing more interesting information.",
  },
];
