export function getColorCode(value) {
  switch (value) {
    case "Yellow":
      return "#ffda1a";
    case "Green Yellow":
      return "#c1e51a";
    case "Greenish Yellow":
      return "#c1e51a";
    case "Green/ish Yellow":
      return "#c1e51a";
    case "Orange Yellow":
      return "#ffa330";
    case "Orangy Yellow":
      return "#ffa330";
    case "Pink":
      return "#d43f91";
    case "Purple/ish Pink":
      return "#d43f91";
      case "Pink/Purple/ish Pink":
      return "#d43f91";
    case "Orangy Pink":
      return "#e08787";
    case "Purplish Pink":
      return "#d43f91";
    case "Pinkish Purple":
      return "#a07aa0";
    case "Pink/ish Purple":
        return "#a07aa0";
    case "Brownish Pink":
      return "#de7394";
    case "Brown Pink":
      return "#bc5b78";
    case "Blue":
      return "#4380c5";
    case "Gray Blue":
      return "#8faac5";
    case "Green Blue":
      return "#55887e";
    case "Grayish Blue":
      return "#8faac5";
    case "Orange":
      return "#ff7c31";
    case "Yellow Orange":
      return "#fa9922";
    case "Yellowish Orange":
      return "#ff8630";
    case "Brown Orange":
      return "#d67f4a";
    case "Green":
      return "#547055";
    case "Yellow Green":
      return "#57ee1a";
    case "Yellow/ish Green":
      return "#57ee1a";
    case "Yellowish Green":
      return "#57ee1a";
    case "Blueish Green":
      return "#326b72";
    case "Red":
      return "#cf2c3e";
    case "Purplish Red":
      return "#cf2c6c";
    case "Gray":
      return "#8f8f8f";
    case "Blue Gray":
      return "#778591";
    case "Blueish Gray":
      return "#778591";
    case "Blue/ish Gray":
      return "#778591";
    case "Purple":
      return "#8c87aa";
    case "Bluish Green":
      return "#6db9a4";
    case "Blue Green":
      return "#49a79e";
    case "Greenish Blue":
      return "#3bb5d2";
    case "Brownish Orange":
        return "#c45924";
    default:
      return "#000";
  }
}
