import React, { useContext, useEffect } from "react";
import {
  DiaryDiamondColors,
  DiaryCauseShape,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";
import { concatenateStrings } from "../../utils/string-utils";
import { clientPath } from "../../config/key";
const DiaryGIA = ({
  reportData,
  imgScaleVal,
  showAllCard,
  handleShowAllCard,
  isHide,
  colorCode,
  sidebarCard
}) => {
  const { setPdfReportData} = useContext(FcrfContext);
  const { data, gia_report, diaryInfo, range_slider } = reportData || {};
  const { results } = gia_report || {};
  const {
    clarity_grade,
    shape_and_cutting_style,
    carat_weight,
    fluorescence,
    measurements,
    color_grade,
  } = results || {};

  //console.log("data>>>>>>>>", data)

  const { saturation } = data || {};

  const result = DiaryDiamondColors.filter(
    (elem) =>
      
      data?.color === elem.value
  );
  const showColorImg = result[0]?.img;
  const resultShapeCode = DiaryCauseShape.filter(
    (elem) => data?.shape_code === elem.code
  );
  const showShapeImgCode = resultShapeCode[0]?.shape;
  const resultShape = DiaryDiamondShapes.filter(
    (elem) => showShapeImgCode === elem.value
  );
  var showShapeImg = resultShape[0]?.img;

  if(results?.data?.shape?.shape_code==="OMB"){
    if(results?.data?.shape?.shape_group==="Cushion"){
      showShapeImg="cushion"
   }
   else{
    showShapeImg = resultShape[0]?.img;
   }
   }


  useEffect(() => {
    const getOriginMine = (value) => {
      if (value === "#NA") return null;
      return value === "" ? "Not Specified" : value;
    };
    //
    // creating pdf data for summary and set in the pdfReportData context
    //
    const giaSummeryBox = {
      carat_weight: carat_weight ? carat_weight : null,
      clarity_grade: clarity_grade ? clarity_grade : null,
      fluorescence: fluorescence ? fluorescence : null,
      color_grade: concatenateStrings([data?.saturation, data?.colorName]),
      measurements: measurements ? measurements : null,
      giaReportNo: gia_report?.report_number ? gia_report?.report_number : null,
      shapeName: range_slider?.shapeName ? range_slider?.shapeName : null,
      originMine: getOriginMine(data?.originmine),
      image: {
        shape: showShapeImg,
        color: showColorImg,
        scale: imgScaleVal,
      },
      colorCode,
      colorName: data?.color,
      saturation:saturation
    };
    setPdfReportData((prev) => ({ ...prev, giaSummeryBox }));
    // if there is change in  carat weight or clarity_grade then set values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carat_weight, clarity_grade, imgScaleVal, data?.originmine]);

  return (
    <section className={`diary-card diary-character-card `} id="giaSummeryBox">
      <div className="showall-card-btn">
        {!isHide && <span htmlFor="nav-showAll">All</span>}
        {!isHide && (
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-showAll`}
              onChange={() => handleShowAllCard()}
              checked={showAllCard}
            />
            <div className="checkmark"></div>
          </label>
        )}
      </div>
      <h4>GIA summary</h4>
      <div className="diary-character-wrapper">
        <div className="diary-character-content desktop">
          {!!carat_weight && (
            <div className="diary-field">
              <span className="diary-label">Carat weight</span>
              <h6 title={carat_weight || ""}>{carat_weight || ""}</h6>
            </div>
          )}
          {!!clarity_grade && (
            <div className="diary-field">
              <span className="diary-label">Clarity grade</span>
              <h6 title={clarity_grade || ""}>{clarity_grade || ""}</h6>
            </div>
          )}
          {!!fluorescence && (
            <div className="diary-field">
              <span className="diary-label">Fluorescence</span>
              <h6 title={fluorescence || ""}>{fluorescence || ""}</h6>
            </div>
          )}
          {!!color_grade && (
            <div className="diary-field">
              <span className="diary-label">Color grade</span>
              <h6 title={color_grade || ""}>
                {/* {range_slider?.colorFullName || ""} */}
                {data?.saturation || ""} {data?.colorName || ""}
              </h6>
            </div>
          )}
          {!!measurements && (
            <div className="diary-field">
              <span className="diary-label">Measurements</span>
              <h6 title={measurements || ""}>{measurements || ""}</h6>
            </div>
          )}
          <div className="diary-field">
            <span className="diary-label">GIA No.</span>
            <h6 title={gia_report?.report_number || ""}>
              {gia_report?.report_number || ""}
            </h6>
          </div>
          {!!range_slider?.shapeName && (
            <div className="diary-field">
              <span className="diary-label">Shape & Cutting</span>
              <h6 title={shape_and_cutting_style || ""}>
                {range_slider?.shapeName || ""}
              </h6>
            </div>
          )}
          {(data?.originmine === "" ||
            diaryInfo?.originInfo?.C46 !== "#N/A") && (
            <div className="diary-field">
              <span className="diary-label">Origin</span>
              <h6 title={data?.originmine || ""}>
                {data?.originmine === "" || !sidebarCard.originBox  ? "Not Specified" : data?.originmine}
              </h6>
            </div>
          )}
        </div>
        <div className="diary-character-content mobile">
          {!!carat_weight && (
            <div className="diary-field">
              <span className="diary-label">Carat weight</span>
              <h6 title={carat_weight || ""}>{carat_weight || ""}</h6>
            </div>
          )}
          <div className="diary-field">
            <span className="diary-label">GIA No.</span>
            <h6 title={gia_report?.report_number || ""}>
              {gia_report?.report_number || ""}
            </h6>
          </div>
          {!!color_grade && (
            <div className="diary-field">
              <span className="diary-label">Color grade</span>
              <h6 title={color_grade || ""}>
                {/* {range_slider?.colorFullName || ""} */}
                {color_grade || ""}
              </h6>
            </div>
          )}
          {!!range_slider?.shapeName && (
            <div className="diary-field">
              <span className="diary-label">Shape & Cutting</span>
              <h6 title={range_slider?.shapeName || ""}>
                {range_slider?.shapeName || ""}
              </h6>
            </div>
          )}
          {!!clarity_grade && (
            <div className="diary-field">
              <span className="diary-label">Clarity grade</span>
              <h6 title={clarity_grade || ""}>{clarity_grade || ""}</h6>
            </div>
          )}
          {!!measurements && (
            <div className="diary-field">
              <span className="diary-label">Measurements</span>
              <h6 title={measurements || ""}>{measurements || ""}</h6>
            </div>
          )}
          {!!fluorescence && (
            <div className="diary-field">
              <span className="diary-label">Fluorescence</span>
              <h6 title={fluorescence || ""}>{fluorescence || ""}</h6>
            </div>
          )}

          {(data?.originmine === "" ||
            diaryInfo?.originInfo?.C46 !== "#N/A") && (
            <div className="diary-field">
              <span className="diary-label">Origin</span>
              <h6 title={data?.originmine || ""}>
                {data?.originmine === "" || !sidebarCard.originBox ? "Not Specified" : data?.originmine}
              </h6>
            </div>
          )}
        </div>
        <div className="diary-character-img">
         {showColorImg && showShapeImg && <img
            className={`diamond-saturation ${saturation || ""}`}
            src={`${clientPath}/images/diamond-${showColorImg}-${showShapeImg}.svg`}
           
            alt={`${showColorImg}-${showShapeImg}`}
            style={{ transform: `scaleY(${imgScaleVal})` }}
          />}
        </div>
      </div>
    </section>
  );
};

export default DiaryGIA;
