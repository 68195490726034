import { useEffect, useRef, useState, useMemo, useContext } from "react";
import { getYear, getQuarterWithYear } from "../../utils/dateUtils";
import FcrfContext from "../../Context";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
const DiaryDiamondIndex = ({
  color,
  data,
  closestDiamondValues,
  closestDiamondDates,
  colorName,
  indexResult,
  saturationName,
  sidebarCard,
  handleSideBarCard,
  appreciation,
  isHide
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const indexBox = useRef(null);
  const [fillValue, setFillValue] = useState(false);
  const [viewOnce, setViewOnce] = useState(false);
  const [minvalue, setMinValue] = useState(0)
  const [maxvalue, setMaxValue] = useState(0)
  const isInViewport = useIsInViewport(indexBox);

  setTimeout(() => {
    setFillValue(viewOnce);
    if (isInViewport) {
      setViewOnce(true);
    }
  }, 500);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const options = {
    chart: {
      renderTo: "container",

     
      type: 'area',
      animation: true,
      zooming: {
        mouseWheel: {
          enabled: false
        }
      },
      style: {
        fontFamily: 'Montserrat'
      }
    },
    exporting: {
      enabled: false
    },

    credits: {
      enabled: false
    },
    title: false,
    subtitle: false,
    xAxis: [{

      crosshair: {
        width: 1,
        color: 'lightgray',
        dashStyle: 'shortdot'
    },
      labels: {

        y: 20,
    
       
        
        reserveSpace:true,
            useHTML: true,  
        align: "center",
        autoRotation: false,
        style: {
          color: "#4c616b",
      fontFamily: "Montserrat",
      fontSize: "11px",
      fontWeight: 0,
      
      textOverflow: "initial",
     
        },
        formatter: function () {
          if (this.isFirst) {
            // Use <p> tag with custom styling for the first label
            return '<p style="padding-left: 25px; color:#4c616b;font-family:Montserrat;font-size: 12px;font-weight:500">' + closestDiamondDates[0]?.split('-')?.at(2) + '</p>';
          } else if (this.isLast) {
            // Use <p> tag with custom styling for the last label
            return '<p style="padding-right: 20px;color:#4c616b;font-family:Montserrat;font-size: 12px;font-weight:500"">' + closestDiamondDates.at(-1)?.split('-')?.at(2) + '</p>';
          } else {
            return ""; // Remove other labels
          }
        }
      },


      lineWidth: 1,
      lineColor: "#daeff5",

      tickLength: 7,
      tickWidth: 0,
      tickInterval: 1,
      tickPosition: "inside",
      tickColor: "#daeff5",
      tickmarkPlacement: "on",
      minorGridLineWidth: 1,
      minorTickInterval: 1,
      startOnTick: true,
      minPadding: 0,
      endOnTick: false,
      maxPadding: 0,
      followPointer: false,
    }],
    yAxis: {
      min: minvalue,
      max: maxvalue,
      title: {
        text: "",
        style: {
          color: "#4a4a4a"
        }
      },
      labels: {
        y: 2,
        x:-7,
        align: "right",
        style: {
          color: "#4c616b",
          fontFamily: "Montserrat",
          fontSize: "12px",
          fontWeight: 500
        }
      },
      // opposite: true,

      lineWidth: 1,
      lineColor: "lightGray",

      gridLineWidth: 1,
     

      tickLength: 10,
      tickWidth: 1,
      tickPosition: "inside",
      tickColor: "#daeff5",

      minorGridLineWidth: 1,

      minorGridLineColor: "lightGray",
      minorTickWidth: 1,
      minorGridPosition: "inside"
    },
    tooltip: {
      
      enabled: true,
      shared: true,
      backgroundColor: 'white',
      shape: 'square',
      padding:0
,      shadow: false,
      useHTML: true,
      fontFamily: "Montserrat",
      formatter: function () {
       
        const dataPointIndex = this.points[0].point.index;
        const closestDiamondDate = closestDiamondDates[dataPointIndex];
        const tooltipContent = `
<div class="arrow_box" style="color: black;font-weight:500;border-radius:6px;width:154px;box-shadow: 0 20px 60px 0 rgba(49,70,93,.2);padding:9px 16px;line-height:20px; font-family: Montserrat,"  >
<span style='font-size:14px;'>${ getQuarterWithYear(closestDiamondDate) }</span><br/>
<span style='font-size: 14px;'>${ this.points[0].y }</span> ${ this.points[0].y >= 100
            ? `<span style='color: green;font-size: 14px;'>(+${ (this.points[0].y - 100).toFixed(
              2
            ) }%)</span>`
            : `<span style='color: red;font-size: 14px;'>(-${ (100 - this.points[0].y).toFixed(
              2
            ) }%)</span>`
          }
</div>
`;

        return tooltipContent;
      },



    },
    legend: false,

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true
            }
          }
        },
        // Configure the gradient fill for the area
        fillColor: {
          linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
          stops: [
            [0, `${ color }00`], // Start color (lighter at the bottom)
            [1, `${ color }99`]   // End color (darker as it approaches the line)
          ]
        },
        lineColor: color,
        lineWidth: 4,

      }
    },

    series: [{
      animation: true,
      data: closestDiamondValues,
      color: color,
      marker: {
        symbol: 'cross',
        lineColor: "#ffff",
        radius: 7,
        states: {
          hover: {
            enabled: true,
            lineWidth: 3, // Set the border width for hover state to 1 (or adjust as needed)
          },
        },
      },

    }],

  };

  useEffect(() => {
    const indexBox = {
      heading: appreciation?.B70,
      appreciationParaData:{
        paraText: appreciation?.C72,
        percentage: indexResult?.total_prec ? (indexResult?.total_prec * 100)?.toFixed(1) + '%' : '0%',
        since: getYear(closestDiamondDates[0])
      },
      chartTextData:{
        initialYear: getYear(closestDiamondDates[0]),
        finalYear: getYear(closestDiamondDates[closestDiamondDates.length - 1])
      },
      compoundAnnuaGrowthRate: (indexResult?.cagr * 100).toFixed(1) + '%' || `0%`,
      yearlyAverage: (indexResult?.simple_avg * 100).toFixed(1) + '%' || `0%`
    };
    setPdfReportData((prev)=>({...prev, indexBox}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appreciation,indexResult]);

  useEffect(() => {
    const maxVal = Math.max(...closestDiamondValues);
    let maxYPoint = maxVal; // Initialize maxYPoint with a default value
  
    if(maxVal < 260){
      maxYPoint *= 1.2;
  }else if(maxVal >= 260 && maxVal <=280){

      maxYPoint = 300;
  }else if(maxVal > 280 && maxVal <=300){
      maxYPoint = 301;
  }else if(maxVal> 340 && maxVal<=350){
      maxYPoint = 351;
  }else if(maxVal >= 380 && maxVal<=480){
      maxYPoint = 401;
  }else if(maxVal >= 480 && maxVal<=500){
      maxYPoint = 501;
  }else if(maxVal > 500 && maxVal % 100 ===0){
      maxYPoint = 601;
  }else if(maxVal >= 580 && maxVal<=600){
      maxYPoint = 601;
    }
  
    let minYPoint = maxYPoint >= 300 && maxYPoint < 400 ? 50 : maxYPoint > 400 ? 0 : Math.min(...closestDiamondValues);
    setMinValue(minYPoint);
    setMaxValue(maxYPoint);

    
  }, [closestDiamondValues]);

 return (
    <section
      className={`diary-card ${ sidebarCard?.indexBox ? "show" : "hide" }`}
      id="indexBox"
    >
      {!isHide &&
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-indexBox`}
              name={`indexBox`}
              onChange={() =>
                handleSideBarCard("indexBox", sidebarCard?.indexBox)
              }
              checked={sidebarCard?.indexBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      }
      {/* <h4 className="title-long-width">The Appreciation of This {colorName}</h4> */}
      <h4 className="title-long-width">{appreciation?.B70 || ""}</h4>
      <div className="diary-card-content diamond-index-card-content">
        <div className="index-card-text">
          <p>
            {appreciation?.C72 || ""}{" "}
            <b>
              {indexResult?.total_prec
                ? (indexResult?.total_prec * 100)?.toFixed(1)
                : ""}
              %
            </b>{" "}
            <span>since {getYear(closestDiamondDates[0])}.</span>
          </p>
          <div className="index-average-data">
            <h6>
              Compound Annual Growth Rate{" "}
              {(indexResult?.cagr * 100).toFixed(1) || 0}%
            </h6>
            <h6>
              Yearly Average {(indexResult?.simple_avg * 100).toFixed(1) || 0}%
            </h6>
          </div>
        </div>
        <div className={`diamond-index-chart-wrapper ${ fillValue ? "" : "" }`} ref={indexBox}>
         
          <div id="container" >
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiaryDiamondIndex;
