import React from "react";
import { useLocation } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  const location = useLocation();
  return (
    <div
      className={`footer-main ${
        location.pathname === "/report" ? "footer-hide" : ""
      }`}
    >
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-contact">
            <span>
              phone:
              <a href="tel:+972-3-5084075" title="Call on: +972-3-5084075">
                +972-3-5084075
              </a>{" "}
            </span>
            <a
              href="https://www.fcresearch.org/contact-us/"
              className="contact-us-btn"
              target="_blank"
              title="Contact Us"
              rel="noreferrer"
            >
              Contact Us
            </a>
            <div className="footer-social-links">
              <a
                href="https://www.facebook.com/fancycolorresearch/"
                target="_blank"
                title="Facebook"
                rel="noreferrer"
              >
                <FbIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/fancy-color-research-foundation/"
                target="_blank"
                title="Linked In"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.instagram.com/fancycolor_research_foundation/"
                target="_blank"
                title="Instagram"
                rel="noreferrer"
              >
                <InstaIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=972737088262&amp;text=Hello, I have a question about the FCRF membership"
          target="_blank"
          title="WhatsApp"
          rel="noreferrer"
        >
          <img src={WhatsappIcon} alt="Whatsapp Icon" />
        </a>
      </div> */}
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <a
            href="https://www.fcresearch.org/privacy-policy/"
            target="_blank"
            title="Privacy Policy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.fcresearch.org/terms-and-conditions/"
            target="_blank"
            title="Terms and Conditions"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
          <span>
            © 2023 The Fancy Color Research Foundation (The FCRF)™. All Rights
            Reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const FbIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_14)">
        <circle
          cx="15.015"
          cy="15.3001"
          r="14.015"
          fill="white"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M12.7029 25.9457C12.8665 25.9457 12.8665 25.9457 12.8665 25.7868C12.8665 22.7582 12.8665 19.7293 12.8665 16.7C12.8665 16.6532 12.8665 16.6064 12.8589 16.5463H12.6996C11.6778 16.5463 10.655 16.5463 9.6332 16.5463C9.52485 16.5463 9.48692 16.5259 9.48692 16.416C9.49126 15.2716 9.49126 14.1275 9.48692 12.9838C9.48692 12.8749 9.52485 12.8535 9.63212 12.8535C10.655 12.8535 11.6768 12.8535 12.6985 12.8535H12.8589C12.8589 12.7914 12.8665 12.7405 12.8665 12.6896C12.8665 11.8751 12.8665 11.0582 12.8665 10.239C12.8589 9.76475 12.9043 9.2911 13.0019 8.82579C13.1839 8.01129 13.535 7.26602 14.1526 6.65208C14.9891 5.81925 16.0477 5.42116 17.2364 5.32851C18.425 5.23586 19.6007 5.32851 20.7795 5.42218C20.8879 5.43033 20.9269 5.46596 20.9269 5.57592C20.9218 6.58387 20.9218 7.59216 20.9269 8.60079C20.9269 8.71176 20.8922 8.74333 20.7763 8.74231C20.1738 8.74231 19.5714 8.73518 18.9689 8.74231C18.6896 8.7419 18.4106 8.76198 18.1346 8.80238C17.5929 8.89095 17.1844 9.15669 17.0262 9.68001C16.9738 9.83577 16.9436 9.99736 16.9362 10.1606C16.9211 11.029 16.9189 11.8975 16.9135 12.766C16.9167 12.7975 16.9222 12.8288 16.9297 12.8596H20.7709C20.7731 12.8997 20.7731 12.9398 20.7709 12.9798C20.6138 14.1201 20.4523 15.2604 20.3006 16.4017C20.2844 16.5269 20.2345 16.5453 20.1186 16.5442C19.1087 16.5442 18.1 16.5442 17.0847 16.5442H16.9081V25.7879C16.9081 25.9436 16.9081 25.9436 17.0738 25.9436"
          fill="#0D1949"
        />
      </g>
      <defs>
        <clipPath id="clip0_61_14">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.285156)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const LinkedInIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16.2852"
        r="14"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.6045 22.4029H8.08992V12.3366H11.6045V22.4029ZM8.00003 9.83114C8.00003 9.30991 8.14685 8.92195 8.44048 8.66726C8.73411 8.41256 9.20752 8.28522 9.8607 8.28522C10.5139 8.28522 10.9903 8.41552 11.2899 8.67614C11.5895 8.93676 11.7394 9.32176 11.7394 9.83114C11.7394 10.8499 11.1131 11.3593 9.8607 11.3593C8.62026 11.3593 8.00003 10.8499 8.00003 9.83114Z"
        fill="#0D1949"
      />
      <path
        d="M20.4854 22.4029V16.9122C20.4854 16.2429 20.3865 15.7394 20.1888 15.4018C19.997 15.0583 19.6854 14.8865 19.254 14.8865C18.6607 14.8865 18.2322 15.1204 17.9686 15.5884C17.7049 16.0563 17.5731 16.8589 17.5731 17.9961V22.4029H14.0585V12.3366H16.7101L17.1506 13.5804H17.3573C17.657 13.1066 18.0704 12.7512 18.5978 12.5143C19.1311 12.2774 19.7424 12.1589 20.4315 12.1589C21.5581 12.1589 22.433 12.4817 23.0562 13.1273C23.6854 13.7729 24 14.6821 24 15.8549V22.4029H20.4854Z"
        fill="#0D1949"
      />
    </svg>
  );
};

const InstaIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16.2852" r="15" fill="white" />
      <g clipPath="url(#clip0_61_21)">
        <path
          d="M20.2995 26.1891H11.7023C10.2161 26.1877 8.79124 25.5968 7.74021 24.5461C6.68918 23.4954 6.09785 22.0707 6.09598 20.5846V11.9856C6.09785 10.4995 6.68918 9.07478 7.74021 8.02408C8.79124 6.97338 10.2161 6.38251 11.7023 6.3811H20.2995C21.7853 6.38298 23.2097 6.97406 24.2604 8.02471C25.311 9.07536 25.9021 10.4998 25.904 11.9856V20.5846C25.9021 22.0704 25.311 23.4949 24.2604 24.5455C23.2097 25.5962 21.7853 26.1872 20.2995 26.1891ZM11.7023 8.15189C10.6855 8.15236 9.71045 8.55636 8.99131 9.27518C8.27216 9.99399 7.8677 10.9688 7.86676 11.9856V20.5846C7.8677 21.6014 8.27216 22.5762 8.99131 23.295C9.71045 24.0139 10.6855 24.4179 11.7023 24.4183H20.2995C21.3159 24.4174 22.2905 24.0132 23.0093 23.2944C23.7281 22.5757 24.1323 21.6011 24.1332 20.5846V11.9856C24.1323 10.9692 23.7281 9.99457 23.0093 9.27581C22.2905 8.55704 21.3159 8.15283 20.2995 8.15189H11.7023Z"
          fill="#0D1949"
        />
        <path
          d="M16 20.9759C14.722 20.9745 13.4969 20.4662 12.5932 19.5626C11.6896 18.659 11.1813 17.4338 11.1799 16.1558C11.1813 14.8777 11.6895 13.6524 12.5931 12.7485C13.4967 11.8445 14.7219 11.3359 16 11.334C17.2782 11.3359 18.5036 11.8445 19.4075 12.7483C20.3113 13.6522 20.82 14.8776 20.8218 16.1558C20.82 17.4339 20.3113 18.6591 19.4074 19.5627C18.5034 20.4663 17.2781 20.9745 16 20.9759ZM16 13.1048C15.1912 13.1057 14.4159 13.4275 13.8442 13.9996C13.2725 14.5716 12.9512 15.3471 12.9507 16.1558C12.9512 16.9644 13.2726 17.7397 13.8443 18.3115C14.4161 18.8832 15.1914 19.2047 16 19.2051C16.8087 19.2047 17.5842 18.8833 18.1563 18.3116C18.7283 17.7399 19.0501 16.9646 19.051 16.1558C19.0501 15.3469 18.7284 14.5714 18.1564 13.9994C17.5844 13.4275 16.8089 13.1057 16 13.1048Z"
          fill="#0D1949"
        />
        <path
          d="M21.2857 12.2619C21.9244 12.2619 22.4421 11.7442 22.4421 11.1055C22.4421 10.4669 21.9244 9.94922 21.2857 9.94922C20.6471 9.94922 20.1294 10.4669 20.1294 11.1055C20.1294 11.7442 20.6471 12.2619 21.2857 12.2619Z"
          fill="#0D1949"
        />
      </g>
      <defs>
        <clipPath id="clip0_61_21">
          <rect
            width="19.808"
            height="19.808"
            fill="white"
            transform="translate(6.09598 6.3811)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
