import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ReactPlayer from "react-player";
import { DiaryOriginVideo } from "../../utils/dairyDiamondsDetail";
import { Button } from "react-bootstrap";

import error from "../../assets/images/error.svg";

import { useState } from "react";
import OriginYoutubeModal from "../Models/originYoutubeModal";
import { DiaryOriginImage } from "../../utils/originImage";
import FcrfContext from "../../Context";
import Lottie from "react-lottie";
import * as animationData from "../loader/Diamondloader3d.json";


const DiaryOrigin = ({
  originInfo,
  sidebarCard,
  handleSideBarCard,
  isHide,
  mineName,
  setSelectedMine,
  updateOrigin,
  updateOriginLoading,
  showNewMinePopup,
  setShowNewMinePopup,
  showOriginContentPopup,
  setShowOriginContentPopup,
  showOriginChangeFeature,
  runVideo,
}) => {
  

  const [originModal, setOriginModal] = useState(false);
  const { setPdfReportData } = useContext(FcrfContext);

  const VideoUrl = DiaryOriginVideo.filter(
    (elem) => originInfo?.B46 === elem.origin
  
  );
 



  

  
  const handleNewMineClick = () => {
    setShowOriginContentPopup(!showOriginContentPopup);
    setShowNewMinePopup(true);
  };

  const handleGIAMine = (e) => {
    setSelectedMine(e.target.value);
  };

  const handleOriginModal = () => {
    setOriginModal(!originModal);
  };

  useEffect(() => {
    const imgUrl = DiaryOriginImage.find(
      (elem) => originInfo?.B46 === elem.origin
    );
    const originBox = {...originInfo, imgUrl};
    setPdfReportData((prev) => ({ ...prev, originBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originInfo]);

  


  return (
    <>
      <section
        className={`diary-card ${sidebarCard?.originBox ? "show" : "hide"}`}
        id="originBox"
      >
        {!isHide && (
          <div className="mobile-hide-card-btn">
            <label className="diary-check-container">
              <input
                type="checkbox"
                id={`nav-originBox`}
                name={`originBox`}
                onChange={() =>
                  handleSideBarCard("originBox", sidebarCard?.originBox)
                }
                checked={sidebarCard?.originBox}
              />
              <div className="checkmark"></div>
            </label>
          </div>
        )}
        <h4 className="title-long-width">{originInfo?.B44}</h4>
        <div className="diary-card-content">
          <div className="video-content update-origin-loader">
            {/* <ServiceAreaMap coords={coordsData} matrix={null} /> */}
            {showNewMinePopup &&
            showOriginContentPopup &&
            showOriginChangeFeature ? (
              <OriginContent
                handleGIAMine={handleGIAMine}
                mineName={mineName}
                updateOrigin={updateOrigin}
                updateOriginLoading={updateOriginLoading}
              />
            ) : (
              <ReactPlayer
                url={`/FCRFOrigin/${VideoUrl[0]?.video}`}
                width="100%"
                height="100%"
                loop={false}
                muted
                playing={!showOriginChangeFeature ? true : runVideo}
                preload="none"
                playsinline
                autoPlay
              />
            )}

            {!showNewMinePopup && showOriginChangeFeature && (
              <VideoOverlay
                handleNewMineClick={handleNewMineClick}
                orgin={originInfo?.B46}
                updateOrigin={updateOrigin}
              />
            )}
          </div>
          <div
            className={` ${
              !showNewMinePopup && showOriginChangeFeature ? "blur" : ""
            }`}
          >          
            <p>{originInfo?.C46}</p>
          </div>
          {originInfo?.E46 ? (
            <div className="diary-origin-youtube">
              <span
                className="rarity-link"
                onClick={handleOriginModal}
                role="presentation"
              >
                {originInfo?.D46}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <OriginYoutubeModal
        originModal={originModal}
        setOriginModal={setOriginModal}
        originInfo={originInfo}
      />
    </>
  );
};

export default DiaryOrigin;

const VideoOverlay = ({ updateOrigin, handleNewMineClick, orgin }) => {
  return (
    <div className="overlay-content">
      <div className="overlay-content-container">
        <div className="overlay-item">
          <img src={error} alt="error" />
          <p>
            Please note that diamonds with this color are unlikely to be
            discovered at the <span>{orgin}</span> mine.
            <br />
            <p>We recommend verifying the origin of this diamond.</p>
          </p>
        </div>
        <div className="overlay-button">
          <Button
            className="no-outline-btn bg-btn"
            onClick={handleNewMineClick}
          >
            Insert new mine
          </Button>
          <Button className="outline-btn" onClick={() => updateOrigin(false)}>
            Continue Anyway
          </Button>
        </div>
      </div>
    </div>
  );
};

const OriginContent = ({
  handleGIAMine,
  mineName,
  updateOrigin,
  updateOriginLoading,
}) => {
  return (
    <div className="origin-content">
      {!updateOriginLoading ? (
        <div className="origin-content-item">
          <Form.Group
            className={`diamond-weight diary-input`}
            controlId="formBasicEmail"
          >
            <Form.Label>Origin / Mine</Form.Label>

            <Form.Select
              aria-label="Default select example"
              onChange={handleGIAMine}
            >
              <option value={""}>Origin - unknown</option>
              {DiaryOriginVideo.map((listItem, i) => (
                <option
                  value={listItem?.origin}
                  key={i}
                  selected={mineName === listItem?.origin}
                >
                  {listItem?.origin}
                </option>
              ))}
              <option>Other</option>
            </Form.Select>
          </Form.Group>
          <Button
            className="no-outline-btn bg-btn"
            onClick={() => updateOrigin(true)}
          >
            Update Origin
          </Button>
        </div>
      ) : (
        <div className="origin-loader imageAnimation">
          {/* <img src={diamond} alt="diamond" className="diamond-loader" /> */}
          <Lottie options={{loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }}}
    />

          <p className="update-origin-text">Origin Updating...</p>
        </div>
      )}
    </div>
  );
};
