import { clientPath } from "../../config/key";
import {
  ArgyleDiamonds,
  ArgyleDiamondShapes,
} from "../../utils/argyleDiamondsDetail";

const DiamondCharacteristics = ({ report , text  }) => {
  
  const { weight, saturation, color, shape, clarity } = report || {};

  const resultColorImg = ArgyleDiamonds.filter(
    (elem) => elem?.color?.value === color
  );
  const diamondColorImg = resultColorImg[0]?.color?.img;
  const resultShapeImg = ArgyleDiamondShapes.filter(
    (elem) => elem?.value === shape
  );
  const diamondShapeImg = resultShapeImg[0]?.img;
  return (
    <div>
      <div className="rarity-card-content">
        <h6>Diamond Characteristics</h6>
        <div className="diamond-characteristics">
          <div className="diamond-characteristics-desc">
            <p>Carat: {weight} Carat</p>
            <p>Saturation: {saturation}</p>
            <p>Color: {color}</p>
            <p>Shape: {shape}</p>
            <p>Clarity: {clarity}</p>
          </div>
          <div className="diamond-characteristics-img">
            <img
              className={`diamond-saturation ${saturation}`}
              src={`${clientPath}/images/diamond-${diamondColorImg}-${diamondShapeImg}.svg`}
              alt={diamondColorImg}
            />
          </div>
        </div>
      </div>
      <div className="rarity-card-content-box">
        <div className="rarity-card-content-inner">
        <h1>rarity</h1>
        <div className="argyle_card_text">
        <p>{`An Argyle stone with identical characteristics`}<span>{text?.split("characteristics")[1]}</span></p>
        </div>
      </div></div>
    </div>
  );
};

export default DiamondCharacteristics;
