/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";
import Lottie from "react-lottie";
import * as animationData from "./Diamondloader3d.json";
import { useContext } from "react";
import FcrfContext from "../../Context";
import "./loader.css";

const Loader = ({ type, handleClose, from }) => {
  const { prevLocation } = useContext(FcrfContext);
  //console.log("type =>", type);
  const wp_base_url = process.env.REACT_APP_WP_BASE_URL;
  const location = useLocation();
  const product_id = localStorage.getItem("product_id");
  const limt20ID = process.env.REACT_APP_PRODUCTID1;
  const limt35ID = process.env.REACT_APP_PRODUCTID2;

  const num =
    product_id === limt20ID ? "20" : product_id === limt35ID ? "35" : "35";
  const textstr = `We're happy to see that you find the Diamond Diary useful!
  Currently, we offer a free trial for up to ${num} reports. Soon, you'll have the opportunity to purchase a package at a promotional price.`;
  const is_subscription = localStorage.getItem("is_subscription")
    ? localStorage.getItem("is_subscription")
    : localStorage.getItem("is_subscription") === "cancelled"
    ? "expired"
    : "expired";

  const is_child_access = localStorage.getItem("is_child_access");
  const child_access = JSON.parse(is_child_access);
  const { parent_user_email } = child_access || {};
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className='loading-screen'>
      <div className={(type === "progress" || type === "shareprogress")?'loader-wrapper loader-gap data-processing':"loader-wrapper"}>
        {type === "progress" ? (
          ""
        ) : type === "shareprogress" ? (
          ""
        ) : (
          <div
            className='close-icon'
            onClick={() => handleClose()}
            role='presentation'
          >
            <CloseButton />
          </div>
        )}
        {type !== "loginalert" &&
        type !== "is_free" &&
        type !== "is_rarity" &&
        type !== "is_expired" &&
        type !== "is_expireddiary" &&
        type !== "is_free_pro" &&
        type !== "is_limit" &&
        type !== "is_upgrade" &&
        type !== "is_free_status" &&
        type !== "is_search_up" &&
        type!=="Activate free account"&&
        type!=="Top-up free account"&&
        type !== "is_search_up_child"&&
        type !== "is_branding" ? (
          <h2>
            {type === "alert" ? (
              "An Error Occurred"
            ) : type === "Become a Member" ||type==="Data Supplier access" ? (
              "Members only access" 
            )  : (
              <></>
            )}
          </h2>
        ) : (
          ""
        )}
        {type === "progress" || type === "shareprogress" ? (
          <div className='imageAnimation'>
            {/* <img src={LoaderIcon} alt='loader-icon' /> */}
            <Lottie options={defaultOptions} />
          </div>
        ) : (
          <AlertIcon />
        )}
        <div
          className={`loader-content ${
            type === "alert"
              ? "alert-content"
              : type === "loginalert" ||
                type === "is_free" ||
                type === "is_rarity"
              ? "login-alert"
              : ""
          }`}
        >
          {type === "alert" && (
            <a href='mailto:customers@fcresearch.org'>
              customers@fcresearch.org
            </a>
          )}
          
          
          <p>
            {type === "alert"
              ? "Please try again later or contact:"
              : type === "loginalert"
              ? "Login Members only access"
              : type === "is_free" ||
                type === "is_rarity" ||
                type === "is_free_pro" ||
                type === "is_member" ||
                type === "is_upgrade" ||
                type === "is_search_up" ||
                type === "is_search_up_child"||
                type === "is_branding"||
                type==="is_data_supplier"
              ? ""
              : type === "is_expired" || type === "is_expireddiary"
              ? `Your membership has ${
                  is_subscription === "cancelled" ? "expired" : is_subscription
                }`
              : (
                type === "is_free_status" || 
                type === "Activate free account"
              )
              ? "Your account has not been activated yet."
              : type==="Top-up free account"?"You've used up the searches included in your free account.": "Please Wait…"}

          </p>
          

          {type === "loginalert" && (
            <div className='login-alert-loader'>
              <span>
                For full access to the Fancy Color Diamond Rarity Report
                please
              </span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/members`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Register
                </a>{" "}
                or
                <a
                  href={`${wp_base_url}/login`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Log-In
                </a>
              </div>
            </div>
          )}
          
          {type === "Become a Member" && (
            <div className='login-alert-loader'>
              <span>
                {from === "diary"
                  ? "For full access to the Diamond Diary Report"
                  : "For full access to the Fancy Color Diamond Rarity & Argyle"}
              </span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/members`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Apply for a Plan
                </a>
              </div>
            </div>
          )}

          {type==="Data Supplier access" && (
            <div className='login-alert-loader'>
              <span>
          <>
         As a Data Supplier you only have access to the index. <br />
         You can upgrade to a Pro-Trader with a 15% discount
        </>
       </span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`https://www.fcresearch.org/checkout/?add-to-cart=53660&coupon=DIS15FORDS`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Upgrade now
                </a>
              </div>
            </div>
          )}
          {type === "is_upgrade" && (
            <div className='login-alert-loader'>
              <span>
                For full access to the Fancy Color Diamond Rarity & Argyle
              </span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/members`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Upgrade your account
                </a>
              </div>
            </div>
          )}

          {(type === "is_search_up" || type === "Upgrade your account") && (
            <div className='login-alert-loader'>
              <span>You've used up the searches included in your account.</span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/members`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Upgrade your account
                </a>
              </div>

              {from === "diary" &&
                (product_id === process.env.REACT_APP_PRO ||
                  product_id === process.env.REACT_APP_PRONEW) && (
                  <>
                    OR
                    <div className='login-alert-content-wrap'>
                      <a
                        href={`${wp_base_url}/diary-credits/`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        buy more credits
                      </a>
                    </div>
                  </>
                )}
            </div>
          )}


          {type === "Buy more credits"  && (
            <div className='login-alert-loader'>
              <span>You've used up the searches included in your account.</span>
              {from === "diary" &&(
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/diary-credits/`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Buy more credits
                </a>
              </div>
              )}
            </div>
          )}

          {type === "is_free" && (
            <div className='login-alert-loader'>
              <span>
                You've used up the searches included in your free trial.
              </span>
              <div className='login-alert-content-wrap'>
                <a
                  href={`${wp_base_url}/members`}
                  target='_blank'
                  rel='noreferrer'
                >
                  Become a Member
                </a>
              </div>
            </div>
          )}
          {type === "progress" ? (
            <div className='process-desc-text'>
              {location.pathname.includes("/sharediary") ||
              location.pathname.includes("/share-diary-report") ||
              location?.state?.from === "diarySearchHistory" ||
              location.pathname.includes("/diary-history-report") ||
              prevLocation === "/diary-history-report" ||
              location.pathname.includes("/diary-save-history") ? (
                <p>Producing a unique report for your diamond</p>
              ) : (
                <p>
                  {location?.state?.from === "diary" ||
                  location.pathname === "/diary" || location.pathname === "/diary-report" ? (
                    <DiaryLoaderText  pathname={location.pathname} />
                  ) : (
                    "Rarity is the epic story of a fancy color diamond"
                  )}
                </p>
              )}
             
            </div>
          ) : (
          

            ""
          )}
          {(type === "is_rarity" || type === "is_expired") && (
            <div className='login-alert-loader'>
              <span>
                For full access to the Fancy Color Diamond Rarity & Argyle
                Report please contact us at
              </span>
              <div className='login-alert-content-wrap'>
                <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a>
              </div>
            </div>
          )}
          {(type === "Rarity has no access to diary") && (
            <div className='login-alert-loader'>
              <span>
              As a Rarity Member you only have access to the Rarity Report.
              To upgrade Yor account please contact the office at
              </span>
              <div className='login-alert-content-wrap'>
                <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a>
              </div>
            </div>
          )}
          {(type === "is_expireddiary" || type === "is_limit") && (
            <div className='login-alert-loader'>
              <span>
                For full access to the Diamond Diary Report please{" "}
                <div className='login-alert-content-wrap'>
                  <a href='https://www.fcresearch.org/membership-plans/'>
                    UPGRADE
                  </a>{" "}
                </div>
                or contact us at
              </span>
              <div className='login-alert-content-wrap'>
                <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a>
              </div>
            </div>
          )}
          {type === "is_free_pro" && (
            <div className='login-alert-loader'>
              <span>{textstr}</span>
            </div>
          )}
          {(
            type === "is_free_status" || 
            type === "Activate free account"
          ) && (
            <div className='login-alert-loader'>
              <span>
                To activate your account, please contact the office at
              </span>
              <div className='login-alert-content-wrap'>
                <a href='mailto:customers@fcresearch.org'>
                  customers@fcresearch.org
                </a>
              </div>
            
            </div>
          )}
        
          {type === "Top-up free account" && (
            <div className='login-alert-loader'>
              <span>
              For full access to the Diamond Report 
              </span>
              <div className='login-alert-content-wrap'>
                <a href='https://www.fcresearch.org/membership-plans/'>
                apply for a plan
                </a>
              </div>
            
            </div>
          )}

          {(type === "is_search_up_child" || type.includes("For additional credits, please contact the main user at") )&& (
            <div className='login-alert-loader-two'>
              <span>You've used up the searches included in your account.</span>
              <span>
                {type}
              </span>
              <div className='login-alert-content-wrap'>
                <a href={`mailto:${parent_user_email}`} rel='noreferrer'>
                  {parent_user_email}
                </a>
              </div>
            </div>
          )}
          {type === "User has no access" && (
            <div className='login-alert-loader-two'>
              <span>You've used up the searches included in your account</span>
              <span>
                For additional credits, please contact the office at{" "}
              </span>
              <div className='login-alert-content-wrap'>
              <a href="mailto:customers@fcresearch.org" rel='noreferrer'>customers@fcresearch.org</a>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Loader;


const DiaryLoaderText = ({pathname}) => {
  const getContext = useContext(FcrfContext);
  const {setIsOriginOption, setShowDiaryReportNow } = getContext;
  const [loaderTxt, setLoaderTxt] = useState( pathname === '/diary-report' ? "Here is your report" :
    "Generating your Diamond Diary report"
  );

  useEffect(() => {
    let firstLoaderTextTimer;
    let secondLoaderTextTimer
       const updateLoaderText = () => {
      setLoaderTxt("Generating your Diamond Diary report");
  
      firstLoaderTextTimer = setTimeout(() => {

        setLoaderTxt("Preparing more interesting information");
  
        secondLoaderTextTimer = setTimeout(() => {
          setLoaderTxt("Here is your report");
          setShowDiaryReportNow(true);
          setIsOriginOption("");
        }, 2000);
      }, 2000);
    };

    if ( pathname==='/diary-report') {
      setShowDiaryReportNow(true);
    }else{
      updateLoaderText();
    }

    
    // Clear the timer when the component unmounts
    return () => {
      clearTimeout(firstLoaderTextTimer);
      clearTimeout(secondLoaderTextTimer);
    };
  }, []);

  return <>{loaderTxt}</>;
};

const AlertIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='80'
      viewBox='0 0 80 80'
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#FA3782'>
          <g>
            <g>
              <path
                d='M40 7.5l37.5 65h-75L40 7.5zm0 6.354L7.985 69.346h64.028L40 13.854zm1.592 16.185v25.16h-3.184v-25.16h3.184z'
                transform='translate(-680 -365) translate(492 301) translate(188 64)'
              />
              <path
                fillRule='nonzero'
                d='M43.192 58.34L36.808 58.34 36.808 64.638 43.192 64.638z'
                transform='translate(-680 -365) translate(492 301) translate(188 64)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};